<template>
  <div>
    <p class="m-0" v-for="(row, i) in rows" :key="i">
      <span class="dot" :style="{backgroundColor: row.color}"></span>
      {{ row.text }}
    </p>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'CalendarText',
  props: ['text'],
  components: {
  },
  data: () => ({
    colors: {
      // 'Amaan': '#09a5d0',
      // 'Kurt': 'orangered',
      // 'Lester': 'navy',
      // 'Anj': 'purple',
      // 'Shanaz': 'lavender',
      // 'Mo': 'crimson',
      // 'VCH': '#008080',
      'Mo': '#e60049',
      'Lester': '#0bb4ff',
      'VCH': '#50e991',
      'Kurt': '#e6d800',
      'Anj': '#9b19f5',
      'Ang': '#9b19f5',
      'Shanaz': '#ffa300',
      'Amaan': '#dc0ab4',
      // '#b3d4ff'
      // '#00bfa0'
    },
  }),
  async mounted() {
  },
  methods: {},
  computed: {
    rows() {
      return (this.text || '').split('\n').map((line) => {
        let color = null;
        Object.keys(this.colors).forEach((c) => {
          if (line.toLowerCase().includes(c.toLowerCase())) {
            color = this.colors[c];
          }
        });
        
        return {
          text: line,
          color,
        };
      });
    },
  },
}
</script>

<style scoped>
div {
  word-wrap: pre;
}

.dot {
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  border-radius: 0.3em;
  line-height: 1em;
  background-color: transparent;
}
</style>