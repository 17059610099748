<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h4 class="text-center">{{ start | moment('MMMM Do') }} - {{ end | moment('MMMM Do') }}</h4>
        <div class="calendarContainer">
          <div class="d-flex flex-column" style="min-width: 700px;">
            <div class="week d-flex flex-row" v-for="(week, i) in thisMonthByWeek" :key="`week-${i}`">
              <div class="day d-flex flex-column" v-for="(day, j) in week" :key="`week-${i}-${j}`">
                <div class="text-center">
                  <p v-if="i === 0" class="m-0">
                    {{ day | moment('ddd') }}
                  </p>
                  <div class="d-flex flex-row justify-content-center">
                    <strong :class="{
                      'text-muted': !day.isSame(thisMonth[8], 'month'),
                      'text-danger': day.isSame(today, 'day'),
                    }" class="px-1">{{ day | moment('D') }}</strong>
                    <button class="btn btn-sm btn-outline-secondary border-0 ms-auto" v-if="superuser" @click="startEditing(day)">✏️</button>
                  </div>
                </div>
                <!-- display text -->
                <div class="day-text">
                  <span v-if="!editingDays[parseInt(day.format('YYYYMMDD'), 10)]">
                    <CalendarText :text="entriesByDayInt[parseInt(day.format('YYYYMMDD'), 10)]?.text" />
                  </span>
                </div>
                <!-- display editing -->
                <div class="d-flex flex-column flex-grow-1 align-items-stretch" v-if="editingDays[parseInt(day.format('YYYYMMDD'), 10)]">
                  <textarea class="w-100 flex-grow-1" v-model="editingDays[parseInt(day.format('YYYYMMDD'), 10)].newText"></textarea>
                  <div class="d-flex flex-row">
                    <button class="btn btn-sm btn-outline-danger" @click="cancel(day)">Cancel</button>
                    <button class="btn btn-sm btn-outline-success ms-auto" @click="save(editingDays[parseInt(day.format('YYYYMMDD'), 10)])">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import CalendarText from './CalendarText.vue';

export default {
  name: 'WeekCalendar',
  components: {
    CalendarText,
  },
  data: () => ({
    entries: [],
    targetDate: moment(new Date()),
    editingDays: {},
    superuser: false,
  }),
  async mounted() {
    this.loadCalendarEntries();

    const { data: me } = await this.$api.get('/me');
    if (me.superuser === true) {
      this.superuser = true;
    }
  },
  methods: {
    async loadCalendarEntries() {
      const { data } = await this.$api.get('/calendar-days', {
        params: {
          start: this.start.clone().subtract(1, 'day').toISOString(),
          end: this.end.clone().add(1, 'day').toISOString(),
        },
      });

      this.entries = data;
    },
    startEditing(momentDate) {
      const dayNum = parseInt(momentDate.format('YYYYMMDD'), 10);

      this.editingDays = {
        ...this.editingDays,
        [dayNum]: {
          dayNum,
          newText: this.entriesByDayInt[dayNum]?.text || '',
        },
      };
    },
    cancel(momentDate) {
      const dayNum = parseInt(momentDate.format('YYYYMMDD'), 10);

      const {
        [dayNum]: _discard, // eslint-disable-line no-unused-vars
        ...newEditingDays
      } = this.editingDays
      
      this.editingDays = newEditingDays;
    },
    async save(editingObject) {
      try {
        await this.$api.post(`/calendar-days`, editingObject);

        delete this.editingDays[editingObject.dayNum];

        this.loadCalendarEntries();
      } catch (e) {
        console.error(e);
        window.alert('Error saving text');
      }
    },
  },
  computed: {
    start() {
      return this.targetDate.clone().startOf('month').startOf('week');
    },
    end() {
      return this.targetDate.clone().endOf('month').endOf('week');
    },
    thisMonth() {
      const start = this.start.clone();
      const end = this.end.clone();

      const days = [];
      let day = start.clone();
      while (end.isAfter(day)) {
        days.push(day.clone());
        day.add(1, 'day');
      }

      return days;
    },
    thisMonthByWeek() {
      const weeks = [];

      for (let i = 0; i < this.thisMonth.length; i += 7) {
        weeks.push(this.thisMonth.slice(i, Math.min(i + 7, this.thisMonth.length)));
      }

      return weeks;
    },
    today() {
      return moment(new Date());
    },
    entriesByDayInt() {
      return this.entries.reduce((p, n) => {
        p[n.dateNum] = n;
        return p;
      }, {});
    }
  },
}
</script>

<style scoped>
.calendarContainer {
  overflow-x: auto;
  width: 100%;
}

.week {
  align-self: stretch;
  align-items: stretch;
  border-bottom: inset 1px #ccc;
}
.day {
  width: 14.25%;
  border-left: inset 1px #ccc;
}
.day-text {
  width: 100%;
  padding: 4px;
  overflow-x: scroll;
  white-space: pre;
}
</style>